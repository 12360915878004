<template>
  <app-menu-form
    :mode="mode"
    :headers="auditPlanItemHeader"
    :serverItems="auditPlanItemItems"
    :clientItems="auditPlanItemItems"
    :loading="loading"
    :serverItemsLength="auditPlanItemServerItemsLength"
    :isSelectable="false"
    :hide-edit="hideEdit"
    :hide-delete="hideDelete"
    :events="eventHandlers"
  >
    <template v-slot:top="{ selected }">
      <app-menu-form-top
        :mode="mode"
        :title="$t('menu.auditPlan')"
        :dialog="dialogAuditPlanItem"
        :selectedLength="selected.length"
        :onDelete="onDeleteAuditTaskItemItem"
        @show-dialog="dialogAuditPlanItem = $event"
        dialogMaxWidth="1500px"
        :onClicked="onNewItem"
        :destroyDialogOnClose="true"
      >
        <template v-slot:dialogForm>
          <v-card flat>
            <v-card-text class="pt-4">
              <v-toolbar dense color="primary white--text">
                <v-toolbar-title>
                  {{ $t('menu.suggestionAuditProgram') }}
                </v-toolbar-title>
              </v-toolbar>
              <v-row class="mt-4">
                <v-col cols="12" sm="6" md="4">
                  <app-input
                    name="company"
                    type="select-server"
                    :label="$t('fields.selectCompany')"
                    :binds="{
                      apiUrl: 'contact/contact/?active=true'
                    }"
                    :view="view"
                    v-model="editedAuditPlan.contact_id"
                    :preFetch="true"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <app-input
                    name="suggestType"
                    type="select"
                    :label="$t('fields.suggestType')"
                    :binds="{
                      apiUrl: 'doc-template/document/?active=true'
                    }"
                    :items="[
                      {
                        label: 'Suggest',
                        value: 'suggest'
                      },
                      {
                        label: 'Display All',
                        value: 'display_all'
                      }
                    ]"
                    v-model="editedAuditPlan.suggest_type"
                  />
                </v-col>
              </v-row>
              <v-row
                v-if="
                  editedAuditPlan.suggest_type !== null &&
                  editedAuditPlan.suggest_type !== undefined
                "
              >
                <v-col cols="12" sm="12">
                  <app-table
                    server-side
                    app="audit"
                    model="auditplanheader"
                    :headers="suggestionHeader"
                    :serverItems="suggestionItemItems"
                    :clientItems="suggestionItemItems"
                    :loading="loading"
                    :server-items-length="suggestionItemServerItemsLength"
                    v-model="selectedSuggest"
                    @server="getSuggestion"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeAuditPlanItem">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="saveAuditPlanItem">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </app-menu-form-top>
    </template>
  </app-menu-form>
</template>

<script>
import AppInput from '@components/AppInput.vue'
import AppMenuForm from '@components/AppMenuForm.vue'
import AppTable from '@components/AppTable.vue'
import AppMenuFormTop from './AppMenuFormTop.vue'

export default {
  name: 'app-audit-plan',
  components: {
    AppTable,
    AppInput,
    AppMenuForm,
    AppMenuFormTop
  },
  props: {
    mode: {
      type: String
    },
    view: {
      type: Boolean
    },
    hideEdit: {
      type: Boolean
    },
    hideDelete: {
      type: Boolean
    },
    formData: {
      type: Object
    },
    from: {
      type: String
    }
  },
  data() {
    return {
      dialogAuditPlanItem: false,
      editedAuditPlan: {},
      selectedSuggest: [],
      selected: [],
      loading: false,
      auditPlanItemItems: [],
      auditPlanItemServerItemsLength: 0,
      suggestionItemItems: [],
      suggestionItemServerItemsLength: 0,
      apiMethod: 'post'
    }
  },
  computed: {
    suggestionHeader() {
      return [
        {
          text: this.$t('fields.auditProgram'),
          value: 'audit_program_name'
        },
        {
          text: this.$t('fields.activityProfile'),
          value: 'audit_activity_profile_name'
        },
        {
          text: this.$t('fields.riskProfile'),
          value: 'audit_risk_profile_name'
        },
        {
          text: this.$t('fields.industry'),
          value: 'audit_industry_name'
        },
        {
          text: this.$t('fields.category'),
          value: 'audit_category_name'
        },
        {
          text: this.$t('fields.businessType'),
          value: 'audit_business_type_name'
        },
        {
          text: this.$t('fields.date'),
          value: 'date',
          hideFilter: true
        }
      ]
    },
    auditPlanItemHeader() {
      return [
        {
          text: this.$t('fields.sequence'),
          value: 'sequence'
        },
        {
          text: this.$t('fields.reference'),
          value: 'reference'
        },
        {
          text: this.$t('fields.taskName'),
          value: 'name'
        },
        {
          text: this.$t('fields.assignTo'),
          value: 'assign_to.name'
        },
        {
          text: this.$t('fields.assignDate'),
          value: 'assign_date'
        },
        {
          text: this.$t('fields.preparedBy'),
          value: 'prepare_by.name'
        },
        {
          text: this.$t('fields.prepareDate'),
          value: 'prepare_date'
        },
        {
          text: this.$t('fields.reviewedBy'),
          value: 'review_by.name'
        },
        {
          text: this.$t('fields.reviewDate'),
          value: 'review_date'
        },
        {
          text: this.$t('fields.deadline'),
          value: 'deadline'
        },
        {
          text: this.$t('label.preAudit'),
          value: 'task_template_header_id.is_pre_audit',
          check: true,
          groupable: false
        },
        {
          text: this.$t('label.audit'),
          value: 'task_template_header_id.is_audit',
          check: true,
          groupable: false
        },
        {
          text: this.$t('label.postAudit'),
          value: 'task_template_header_id.is_post_audit',
          check: true,
          groupable: false
        },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: true,
          groupable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions_view',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    eventHandlers() {
      return {
        server: this.getAuditPlanItemItems,
        delete: this.onDeleteAuditTaskItemItem,
        edit: this.onEditTask,
        view: this.onEditTask
      }
    }
  },
  methods: {
    getAuditPlanItemItems(options = null) {
      this.loading = true
      if (options) {
        const offset = (options.page - 1) * options.itemsPerPage
        var ordering = options.sortBy
          .reduce(
            (acc, value, index) => [
              ...acc,
              `${options.sortDesc[index] ? '-' : ''}${value.replace('.', '__')}`
            ],
            []
          )
          .join(',')

        if (options.sortBy.length == 0) {
          ordering = 'sequence'
        }

        this.params = {
          limit: options.itemsPerPage,
          offset: offset,
          search: options.search,
          ordering: ordering,
          ...options.multiSearch
        }
      } else {
        this.params = {
          ordering: 'sequence'
        }
      }
      if (this.from == 'audit-plan-header') {
        this.params.audit_plan_id = this.$route.params.id
      } else {
        this.params.audit_id = this.$route.params.id
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `task/task/`,
          params: this.params,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.auditPlanItemServerItemsLength = data.count
          this.auditPlanItemItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    onDeleteAuditTaskItemItem(item) {
      this.onDeleteAuditTaskItem(item)
    },
    onDeleteAuditTaskItem(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getAuditPlanItemItems()
        })
      })
      this.loading = false
    },
    onEditTask(item) {
      var query = { from: this.from }
      if (this.from == 'audit-plan-header') {
        query['audit_plan_id'] = this.$route.params.id
      } else if (this.from == 'audit-header') {
        query['audit_id'] = this.$route.params.id
      }
      this.$router.push({
        name: 'taskEdit',
        params: { id: item.id },
        query: query
      })
    },
    closeAuditPlanItem() {
      this.editedAuditPlan = {}
      this.dialogAuditPlanItem = false
    },
    async saveAuditPlanItem() {
      this.loading = true
      const data = {}
      if (this.from == 'audit-plan-header') {
        data.audit_plan_id = this.$route.params.id
      } else if (this.from == 'audit-header') {
        data.audit_id = this.$route.params.id
      }
      if (
        this.editedAuditPlan.contact_id !== null &&
        this.editedAuditPlan.contact_id !== undefined
      ) {
        data.contact_id = this.editedAuditPlan.contact_id.id
      }
      if (
        this.editedAuditPlan.suggest_type !== null &&
        this.editedAuditPlan.suggest_type !== undefined
      ) {
        data.suggest_type = this.editedAuditPlan.suggest_type
      }
      data.ids = this.selectedSuggest.map((value) => value.audit_program_id)

      await this.$api({
        method: this.apiMethod,
        url: `/audit/audit-suggest/`,
        data
      })

      this.loading = false
      this.getAuditPlanItemItems()
      this.closeAuditPlanItem()
    },
    getSuggestion(query = { page: 1, itemsPerPage: 10 }) {
      const offset = (query.page - 1) * query.itemsPerPage
      this.$api({
        method: 'get',
        url: `/audit/audit-plan-suggest/?contact_id=${this.editedAuditPlan.contact_id.id}&suggest_type=${this.editedAuditPlan.suggest_type}`,
        hideSuccessAlert: true,
        params: {
          limit: query.itemsPerPage,
          offset: offset,
          ...query?.multiSearch
        }
      }).then(({ data }) => {
        this.suggestionItemServerItemsLength = data.count
        this.suggestionItemItems = data.results
      })
    },
    onNewItem() {
      if (this.formData?.contact_id) {
        this.editedAuditPlan.contact_id = this.formData.contact_id
      }
    }
  }
}
</script>
