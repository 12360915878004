<template>
  <app-menu-form
    model="auditplanactivitymatrix"
    :headers="activityMatrixItemHeader"
    :serverItems="activityMatrixItemItems"
    :clientItems="activityMatrixItemItems"
    :loading="loading"
    :serverItemsLength="activityMatrixItemServerItemsLength"
    :isSelecteable="false"
    :events="eventHandlers"
  >
    <template v-slot:top>
      <app-menu-form-top
        title="Activity Matrix"
        :dialog="dialogActivityMatrix"
        dialogMaxWidth="1500px"
        :isActivator="false"
      >
        <template v-slot:dialogForm>
          <v-card>
            <v-card-text class="pt-4">
              <v-toolbar-title>Task</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <app-table
                server-side
                app="audit"
                model="task"
                :headers="activityTaskItemHeader"
                :serverItems="activityTaskItemItems"
                :clientItems="activityTaskItemItems"
                :loading="loading"
                :server-items-length="activityTaskItemServerItemsLength"
                :isSelecteable="false"
                @server="getActivityTask"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeActivityMatrix">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </app-menu-form-top>
    </template>
  </app-menu-form>
</template>

<script>
import AppTable from '@components/AppTable.vue'
import AppMenuForm from '@components/AppMenuForm.vue'
import AppMenuFormTop from './AppMenuFormTop.vue'

export default {
  name: 'app-activity-matrix',
  components: {
    AppTable,
    AppMenuForm,
    AppMenuFormTop
  },
  props: {
    isPlan: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogActivityMatrix: false,
      activityMatrixProfileId: null,
      activityMatrixItemItems: [],
      activityMatrixItemServerItemsLength: 0,
      activityTaskItemItems: [],
      activityTaskItemServerItemsLength: 0,
      loading: false
    }
  },
  computed: {
    activityMatrixItemHeader() {
      return [
        {
          text: this.$t('fields.activityProfile'),
          value: 'name',
          hideFilter: true
        },
        {
          text: this.$t('fields.task'),
          value: 'audit_activity_profile_task_count',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions_view',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    activityTaskItemHeader() {
      return [
        {
          text: this.$t('fields.reference'),
          value: 'reference',
          hideFilter: true
        },
        {
          text: this.$t('fields.sequence'),
          value: 'sequence',
          hideFilter: true
        },
        {
          text: this.$t('fields.sectionName'),
          value: 'section_name',
          hideFilter: true
        },
        {
          text: this.$t('fields.assignTo'),
          value: 'assign_to',
          hideFilter: true
        },
        {
          text: this.$t('fields.assignDate'),
          value: 'assign_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.preparedBy'),
          value: 'prepare_by.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.prepareDate'),
          value: 'prepare_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.reviewedBy'),
          value: 'review_by.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.reviewDate'),
          value: 'review_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.deadline'),
          value: 'deadline',
          hideFilter: true
        },
        {
          text: this.$t('label.preAudit'),
          value: 'is_pre_audit',
          check: true,
          hideFilter: true
        },
        {
          text: this.$t('label.audit'),
          value: 'is_audit',
          check: true,
          hideFilter: true
        },
        {
          text: this.$t('label.postAudit'),
          value: 'is_post_audit',
          check: true,
          hideFilter: true
        },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: true,
          groupable: false,
          hideFilter: true
        }
      ]
    },
    eventHandlers() {
      return {
        server: this.getActivityMatrixItemItems,
        view: this.onViewActivityTask
      }
    }
  },
  methods: {
    getActivityMatrixItemItems(options = null) {
      this.loading = true
      if (options) {
        const offset = (options.page - 1) * options.itemsPerPage
        var ordering = options.sortBy
          .reduce(
            (acc, value, index) => [
              ...acc,
              `${options.sortDesc[index] ? '-' : ''}${value.replace('.', '__')}`
            ],
            []
          )
          .join(',')

        this.params = {
          limit: options.itemsPerPage,
          offset: offset,
          search: options.search,
          ordering: ordering,
          ...options.multiSearch
        }
      }
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: this.isPlan
            ? `audit/audit-plan-activity-matrix/?audit_plan_header_id=${this.$route.params.id}&audit_header_id=0`
            : `audit/audit-plan-activity-matrix/?audit_header_id=${this.$route.params.id}&audit_plan_header_id=0`,
          hideSuccessAlert: true,
          params: this.params
        }).then(({ data }) => {
          this.activityMatrixItemServerItemsLength = data.count
          this.activityMatrixItemItems = data.results
        })
      }
      this.loading = false
    },
    onViewActivityTask(item) {
      this.activityMatrixProfileId = item.id
      this.getActivityTask()
      this.dialogActivityMatrix = true
    },
    getActivityTask() {
      if (
        this.activityMatrixProfileId !== null &&
        this.activityMatrixProfileId !== undefined
      ) {
        this.$api({
          method: 'get',
          url: this.isPlan
            ? `audit/audit-plan-activity-matrix/detail/?activity_profile_id=${this.activityMatrixProfileId}&audit_plan_header_id=${this.$route.params.id}&audit_header_id=0`
            : `audit/audit-plan-activity-matrix/detail/?activity_profile_id=${this.activityMatrixProfileId}&audit_header_id=${this.$route.params.id}&audit_plan_header_id=0`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.activityTaskItemServerItemsLength = data.count
          this.activityTaskItemItems = data.results
        })
      }
      this.loading = false
    },
    closeActivityMatrix() {
      this.activityTaskItemItems = []
      this.activityTaskItemServerItemsLength = 0
      this.dialogActivityMatrix = false
    }
  }
}
</script>
